import React, { Component } from 'react';
import './location.css';
import {GpsIcon} from '../../icons/svgIcons';

class Location extends Component {
    state = {isCollapsed : true};
    handleCollapseAction = () => {
        this.setState({isCollapsed : !this.state.isCollapsed});
    }
    render() {
        var big = this.props.big ? "col-lg-12" : "col-lg-6";
       
        return (
            <div className={"location-component col-sm-12 "+big}>
                <div onClick = {this.handleCollapseAction} className="location-component-header">
                    <span className="location-header-icon">
                        <GpsIcon height={35} />
                    </span>
                    <span className={this.state.isCollapsed? "location-header-heading" : "location-header-heading-full"}>
                        {this.props.heading}
                    </span>
                    <span className={this.state.isCollapsed ? "location-header-price":"location-header-price-invisible "}>
                        {this.props.free ? "(free delivery)" : "(small fee)"}
                    </span>
                </div>
                <div className={!this.state.isCollapsed ? "location-component-body animated fadeIn" : "display-none"}>
                    {this.props.bodyText}
                </div>
            </div>
        );
    }
}

export default Location;