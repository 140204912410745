import React, { Component } from 'react';
import './emailForm.css';
import Dropdown from '../dropdown/dropdown';
import  {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons';
import Button from '../button/button';
import * as EmailValidator from 'email-validator';
import ModalInfo from '../modal/modalInfo';

var cars = ["Volkswagen UP","Volkswagen Polo","Volkswagen Golf","Volkswagen T-Roc","Volkswagen Touran","Volkswagen Passat"];
var locations = ["Makarska","Baška Voda","Baško Polje","Bratuš","Brela","Drvenik","Gradac","Igrane","Krvavica","Podaca","Podgora","Promajna","Tučepi","Živogošće","Airport Dubrovnik","Airport Split","Airport Zadar","Airport Zagreb","Other"];

class EmailFrom extends Component {
    constructor(props)
    {
        super(props);
        var pLocation = null;
        var pDate = null;
        var rDate = null;
        if(this.props.selection)
        {
            pLocation = this.props.selection.pickupLocation;
            rDate = this.props.selection.returnDate;
            pDate = this.props.selection.pickupDate;
        }
        

        this.state = {
            name: "",
            email : "",
            phoneNumber : "",
            transmission : this.props.transmission,
            car : this.props.car.fullName,
            pickupLocation : pLocation,
            returnLocation : null,
            pickupDate : pDate,
            returnDate : rDate,
            additionalNote : "",
            carsVisible: false,
            pickupLocationsVisible : false,
            returnLocationsVisible : false,
            pickupVisible : false,
            returnVisible : false,
            isCarValid : true,
            isEmailValid : true,
            isPickupLocationValid : true,
            isReturnLocationValid: true,
            isPickupDateValid : true,
            isReturnDateValid:true,
            showModal:false,
        }
        this.sendMail = this.sendMail.bind(this);
    }
    componentWillReceiveProps(nextProps)
    {
        if(this.props.car !== nextProps.car)
        {
            var currentTransmission = this.state.transmission;
            if(nextProps.car.fullName === "Volkswagen Passat")
            {
                currentTransmission = "Automatic";
            }
            this.setState({car : nextProps.car.fullName, transmission : currentTransmission});
        }
    }
    handleCarSelected = (selected) => {
        if(this.state.car !== selected)
        {
            this.props.handleCarChange(selected);
        }
        this.setState({
            carsVisible: false,
            pickupLocationsVisible : false,
            returnLocationsVisible : false,
            pickupVisible : false,
            returnVisible : false,
        });
    }
    handleTransmissionSelected = (selected) => {
        this.setState({
            transmission : selected,
            transmissionVisible : false,
            carsVisible: false,
            pickupLocationsVisible : false,
            returnLocationsVisible : false,
            pickupVisible : false,
            returnVisible : false,
        });
    }
    handlePickupLocationSelected = (selected) => {
        this.setState({
            pickupLocation : selected,
            pickupLocationsVisible : false,
            returnLocationsVisible : false,
            carsVisible: false,
            transmissionVisible : false,
            locationsVisible : false,
            pickupVisible : false,
            returnVisible : false,});
    }
    handleReturnLocationSelected = (selected) => {
        this.setState({
            returnLocation : selected,
            pickupLocationsVisible : false,
            returnLocationsVisible:false,
            carsVisible: false,
            transmissionVisible : false,
            locationsVisible : false,
            pickupVisible : false,
            returnVisible : false,});
    }
    handlePickupDateSelected = (selected) => {
        
        var returnDate = this.state.returnDate;
        if(returnDate && selected - returnDate > 0)
        {
            returnDate  = null;
        }
        
        this.setState({pickupDate : selected,
            returnDate : returnDate,
            carsVisible: false,
            transmissionVisible : false,
            pickupLocationsVisible : false,
            returnLocationsVisible : false,
            pickupVisible : false,
            returnVisible : false,});
    }
    handleReturnDateSelected = (selected) => {
        this.setState({returnDate : selected,
            carsVisible: false,
            transmissionVisible : false,
            pickupLocationsVisible : false,
            returnLocationsVisible : false,
            pickupVisible : false,
            returnVisible : false,});
    }
    closeOthers = (key) => {
       switch(key)
       {
           case 0 : 
            this.setState({
                carsVisible : !this.state.carsVisible,
                pickupLocationsVisible : false,
                transmissionVisible : false,
                returnLocationsVisible : false,
                pickupVisible : false,
                returnVisible : false,
                isCarValid : true,
            }); 
            break;
           case 1:
            this.setState({
                carsVisible : false,
                pickupLocationsVisible : !this.state.pickupLocationsVisible,
                returnLocationsVisible : false,
                transmissionVisible : false,
                pickupVisible : false,
                returnVisible : false,
                isPickupLocationValid : true,
            }); 
           break;
           case 2: 
           this.setState({
                carsVisible : false,
                pickupLocationsVisible : false,
                returnLocationsVisible : !this.state.returnLocationsVisible,
                pickupVisible : false,
                transmissionVisible : false,
                returnVisible : false,
                isReturnLocationValid : true,
            }); 
           break;
           case 3: 
           this.setState({
                carsVisible : false,
                pickupLocationsVisible : false,
                returnLocationsVisible : false,
                transmissionVisible : false,
                pickupVisible : !this.state.pickupVisible,
                returnVisible : false,
                isPickupDateValid : true}); 
           break;
           case 4 : 
           this.setState({
                carsVisible : false,
                transmissionVisible : false,
                pickupLocationsVisible : false,
                returnLocationsVisible : false,
                pickupVisible : false,
                returnVisible : !this.state.returnVisible,
                isReturnDateValid : true,
            }); 
            break;
            case 5 : 
            this.setState({
                 carsVisible : false,
                 transmissionVisible : !this.state.transmissionVisible,
                 pickupLocationsVisible : false,
                 returnLocationsVisible : false,
                 pickupVisible : false,
                 returnVisible : false,
             }); 
             break;
           default : break;
       }
    }
    handleNameChange = (event) => {
           this.setState({name : event.target.value});
    }
    handleEmailChange = (event) => {
        this.setState({email : event.target.value,isEmailValid : true,});
    }
    handlePhoneNumberChange = (event) => {
        this.setState({phoneNumber : event.target.value});
    }
    handleNoteChange = (event) => {
        this.setState({additionalNote : event.target.value});
    }
    
    handleFormSubmit = () =>{
        var email = true;
        var car = true;
        var pickupLocation = true;
        var returnLocation = true;
        var pickupDate = true;
        var returnDate = true;

        if(!EmailValidator.validate(this.state.email))
           email = false;
        if(!this.state.car)
            car = false;
        if(!this.state.pickupLocation)
            pickupLocation = false;
        if(!this.state.returnLocation)
            returnLocation = false;
        if(!this.state.pickupDate)
            pickupDate = false;
        if(!this.state.returnDate)
            returnDate = false;
        
        if(email && car && pickupLocation && returnLocation && pickupDate && returnDate)
        {
            this.sendMail();
            this.setState({showModal : true,isLoading : true});
        }
        else
        {
            this.setState({
                isCarValid : car,
                isEmailValid : email,
                isPickupLocationValid : pickupLocation,
                isReturnLocationValid:returnLocation,
                isPickupDateValid : pickupDate,
                isReturnDateValid:returnDate
            });
            if(window.innerWidth < 1170)
            {
                window.scrollTo({
                    top:685,
                    behavior:'smooth',    
                });
            }
        }
    }
    async sendMail(){
        var data = {
            name : this.state.name,
            email : this.state.email,
            phoneNumber : this.state.phoneNumber,
            transmission : this.state.transmission,
            pickupLocation : this.state.pickupLocation,
            returnLocation : this.state.returnLocation,
            pickupDate : this.state.pickupDate.toLocaleDateString(),
            returnDate : this.state.returnDate.toLocaleDateString(),
            car : this.state.car,
            note : this.state.additionalNote,
        };
        
        fetch("https://us-central1-falco-30c69.cloudfunctions.net/app/email",
        {
            method : 'POST',
            body : JSON.stringify(data),
            
        })
        .then(res => {
            if(res.status === 200)
                this.setState({success : true, message : "Email was sent successfully. We will contact you in less than 24 hours.", isLoading : false, showPhoneCallModalMessage : true})
            else
                this.setState({success : false, message : "There was a problem handling your request. Please try again.",isLoading : false, showPhoneCallModalMessage : false})
        })
        .catch(() => this.setState({success : false,message:"There was a problem handling your request. Please check your Internet connection and try again.",isLoading : false, showPhoneCallModalMessage:false}))
    }
    closeModal = () => {
        this.setState({showModal : false});
    }
    render() {
        var emailValid = "";
        var carValid = "";
        var PLValid = "";
        var RLValid = "";
        var PDValid = "";
        var RDValid = "";
        if(!this.state.isEmailValid)
            emailValid = "text-input-error";
        if(!this.state.isCarValid)
            carValid = "text-input-error";
        if(!this.state.isPickupLocationValid)
            PLValid = "text-input-error";
        if(!this.state.isReturnLocationValid)
            RLValid = "text-input-error";
        if(!this.state.isPickupDateValid)
            PDValid = "text-input-error";
        if(!this.state.isReturnDateValid)
            RDValid = "text-input-error";

        var emailMessage = "Please fill required field."
        if(this.state.email.length > 0)
        emailMessage = "Please enter valid email address."
        return (
            <div className="email-form">
                <span className="email-form-row">
                    <input type="text" className={this.state.name.length > 0 ? "text-input text-input-active":"text-input"} value={this.state.name} onChange={this.handleNameChange} placeholder="Name" />
                    <span>
                        <input type="text" className={this.state.email.length > 0 ? "text-input text-input-active "+emailValid:"text-input "+emailValid} value={this.state.email} onChange={this.handleEmailChange} placeholder="*Email adress" />
                        <label className={this.state.isEmailValid ? "hidden":"error-label"}>{emailMessage}</label>
                    </span>
                    <input type="phone" className={this.state.phoneNumber.length > 0 ? "text-input text-input-active":"text-input"} value={this.state.phoneNumber} onChange={this.handlePhoneNumberChange} placeholder="Phone number" />
                </span>
                <span className="email-form-row">
                    <span>
                        <Dropdown id={0}  isError = {!this.state.isCarValid} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.carsVisible} title={this.state.car? this.state.car:"*Pick a car"} active = {this.state.car ? true:false} getSelectedValue = {this.handleCarSelected} options = {cars} icon={faAngleDown}/>
                        <label className={this.state.isCarValid ? "hidden":"error-label"}>Please fill required field.</label>
                    </span>
                    {
                        this.state.car !== "Volkswagen Passat" ? 
                        <Dropdown id={5} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.transmissionVisible} title={this.state.transmission? this.state.transmission:"Transmission"} active = {this.state.transmission ? true:false} getSelectedValue = {this.handleTransmissionSelected} options = {["Automatic","Manual"]} icon={faAngleDown}/>
                        :
                        <Dropdown id={5} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {false} title={"Automatic"} active = {true} options = {["Automatic","Manual"]} hideIcon = {true} icon={faAngleDown}/>
                    }
                    <span>
                        <Dropdown id={1} isError = {!this.state.isPickupLocationValid} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.pickupLocationsVisible} title={this.state.pickupLocation? this.state.pickupLocation:"*Pickup location"} active = {this.state.pickupLocation ? true:false} getSelectedValue = {this.handlePickupLocationSelected} options = {locations} icon={faAngleDown}/>
                        <label className={this.state.isPickupLocationValid ? "hidden":"error-label"}>Please fill required field.</label>
                    </span>
                </span>
                <span className="email-form-row">
                    <span>
                        <Dropdown id={2} isError = {!this.state.isReturnLocationValid} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.returnLocationsVisible} title={this.state.returnLocation? this.state.returnLocation:"*Return location"} active = {this.state.returnLocation ? true:false} getSelectedValue = {this.handleReturnLocationSelected} options = {locations} icon={faAngleDown}/>
                        <label className={this.state.isReturnLocationValid ? "hidden":"error-label"}>Please fill required field.</label>
                    </span>
                    <span>
                        <Dropdown id={3} haveDate = {this.state.pickupDate} isError = {!this.state.isPickupDateValid} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.pickupVisible} title={this.state.pickupDate? this.state.pickupDate.toLocaleDateString():"*Pickup date"} active = {this.state.pickupDate ? true:false} icon={faCalendarAlt} isDateDropdown = {true} handleDateSelection={this.handlePickupDateSelected} isPickupCalendar = {true}/>
                        <label className={this.state.isPickupDateValid ? "hidden":"error-label"}>Please fill required field.</label>
                    </span>
                    <span>
                        <Dropdown id={4} haveDate = {this.state.returnDate} isError = {!this.state.isReturnDateValid} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.returnVisible} title={this.state.returnDate? this.state.returnDate.toLocaleDateString():"*Return date"} active = {this.state.returnDate ? true:false} pickupDate = {this.state.pickupDate} icon={faCalendarAlt} isDateDropdown = {true} handleDateSelection={this.handleReturnDateSelected} isPickupCalendar = {false}/>
                        <label className={this.state.isReturnDateValid ? "hidden":"error-label"}>Please fill in required field.</label>
                    </span>
                </span>
                <span>
                    <textarea type="text" className={this.state.additionalNote.length > 0 ? "text-input text-input-area text-input-active":"text-input text-input-area"} value={this.state.additionalNote} onChange={this.handleNoteChange} placeholder="Additional note" />
                </span>
                <div className="email-form-bottom">
                    <span className="email-form-bottom-message">
                        In "Aditional note" field you can out any other requests as Extras, or any other thing that is not listed in fields above.
                    </span>
                    <Button handleClickEvent={this.handleFormSubmit} width="130px" text="SUBMIT" ></Button>
                </div>
                <ModalInfo backToHome = {this.props.backToHome} visible={this.state.showModal} showPhoneCallMessage = {this.state.showPhoneCallModalMessage} isLoading = {this.state.isLoading} success = {this.state.success} message={this.state.message} closeModal = {this.closeModal} />
            </div>
        );
    }
}

export default EmailFrom;