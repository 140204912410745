import React, { Component } from 'react';
import './privacyPolicy.css';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Helmet } from 'react-helmet';
class PrivacyPolicy extends Component {
    componentDidMount(){
        window.scrollTo(0,0);
    }
    render() {
        return (
            <div className="pp-wrap">
                <Helmet>
                    <title>Falco - Privacy Policy</title>
                    <meta name="description" 
                    content="" 
                    />
                </Helmet>
                <Header primary ={"We care about personal data!"}/>
                <div className="pp">
                    <div className="pp-text">
                        Falco, obrt za usluge Kralja Petra Krešimira IV, broj 23, 21300 Makarska, OIB: 04965809364
                        reffered in text below as „Falco Car Rental“ gives great importance to data protection and
                        your privacy. Some data that we collect may be considered personal and we are protecting it
                        in accordance with EU Regulation 2016/679 (General Data Protection Act).
                        Falco Car Rental only collects and uses personal data in accordance with the provisions of
                        law. You can contact us at any time using e-mail <a className="pp-mail" href="mailto:info@makarska.rent">info@makarska.rent</a> or the postal address
                        for Falco, obrt za usluge, Kralja Petra Krešimira IV, br. 23, 21300 Makarska, Croatia if you
                        have any questions or suggestions regarding data protection at our website. Our aim is to
                        ensure the maximum level of data protection and data security.
                    </div>
                    <div className="pp-point">
                        <div className="pp-heading">
                            1. Why do we process your data?
                        </div>
                        <div className="pp-text">
                            Falco Car Rental process your data only for the purposes justified by our services. We collect
                            data so we could provide a vehicle reservation service, conclude a rental agreement and
                            provide a bill, respond to your inquiries, complaints, and reclamations. We act with your data
                            in accordance with the law. We have the appropriate technical and administrative security
                            measures to ensure that our users data is protected from unauthorized or accidental access,
                            use, alteration, or loss.
                        </div>
                    </div>

                    <div className="pp-point">
                        <div className="pp-heading">
                            2. Do we share your data with third parties?
                        </div>
                        <div className="pp-text">
                            Falco Car Rental may submit your data to third parties only in this cases:
                            <ul><br />
                            <li className="pp-list-el">if the law requires us to deliver your data to courts or law authorities</li>
                            <li className="pp-list-el">for the protection of the rights and property of Falco Car Rental</li>
                            <li className="pp-list-el">to prevent or limit the damage that could be made to Falco Car Rental</li>
                            </ul> 
                        </div>
                    </div>

                    <div className="pp-point">
                        <div className="pp-heading">
                            3. Your rights
                        </div>
                        <div className="pp-text">
                            We shall inform you at your written request and within a reasonable period of time of the type
                            of data you stored with us, the purpose of the storage and possible recipients or types of
                            recipient. We will also correct, delete and/or block personal data for further processing at your
                            request within the limits of what is permitted by law, if it proves to be factually incorrect,
                            incomplete or irrelevant for the processing.
                        </div>
                    </div>

                    <div className="pp-point">
                        <div className="pp-heading">
                            4. Contact
                        </div>
                        <div className="pp-text">
                            If you need or want to know more about your personal data or want to achieve your rights,
                            you can contact us by e-mail: info@makarska.rent , or by postal service to:<br />
                            Falco, obrt za usluge<br/><br/>
                            Kralja Petra Krešimira IV, br. 23 <br/>
                            21300 Makarska <br/>
                            Croatia
                        </div>
                    </div>

                </div>
                <Footer />
            </div>
        );
    }
}

export default PrivacyPolicy;