import React, { Component } from 'react';
import './footer.css';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import {Link} from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <div className="footer-wrap">
                <div className="footer">
                <div className="footer-section1">
                    <span className="footer-section1-companyName">
                        Falco, obrt za usluge
                    </span>
                    <span className="footer-section1-link first-link"><Link className="footer-link" to ="/contact">Contact</Link></span>
                    <span className="footer-section1-link"><Link className="footer-link" to ="/termsOfService">Terms of service</Link></span>
                    <span className="footer-section1-link"><Link className="footer-link" to ="/privacyPolicy">Privacy Policy</Link></span>

                </div>
                <div className="footer-section2">
                    <span className="footer-section2-element">
                        <span className="footer-section2-icon"><FontAwesomeIcon icon={faPhone} /></span>
                        <span className="footer-section2-text"><a className="footer-mail" href="tel:+385 98 363 840"><b>+385 98 363 840</b></a></span>
                    </span>
                <span className="footer-section2-element">
                    <span className="footer-section2-icon"><FontAwesomeIcon icon={faEnvelope} /></span>
                    <span className="footer-section2-text"><a className="footer-mail" href="mailto:info@makarska.rent">info@makarska.rent</a></span>
                </span>
                </div>
                </div>
            </div>
        );
    }
}

export default Footer;