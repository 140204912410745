import React, { Component } from 'react';
import './contact.css';
import ContactForm from './contactForm';
import Header from '../header/header';
import Footer from '../footer/footer';
import {Helmet} from 'react-helmet';

class Contact extends Component {
    redirectToHome = () => {
        this.props.history.push("/");
        window.scrollTo(0,0);
    }
    componentDidMount(){
        window.scrollTo(0,0);
    }
    render() {
        return (
            <div className="contact-wrap">
                <Helmet>
                    <title>Falco - Contact</title>
                    <meta name="description" content="We are here to help you! Best Car Rental company in Makarska will help you with any request. If you have any question about renting a car or you have special request feel free to contact us" />
                </Helmet>
                 <Header 
                    primary = {"Feel free to contact us with any question you have!"}
                    secondary_first = {"We will answer in a short time."} 
                    secondary_second={null}
                    />
                <div className="contact">
                    <div className="about-us">
                        <span className="contact-heading">About us</span>
                        <span className="about-us-text">
                            Falco Rental is a local rent a car company that operates from a small town of Makarska. We provide car rental service all along Makarska Riviera but we also deliver cars on any airport in Croatia.
                            <br />We do our best to make our customers happy an providing them best service they can get. Like every other car rental we offer a lot of special offers and extras.
                        </span>
                    <div className="contact-section">
                        <div className="contact-info">
                            <span className="contact-heading">Info</span>
                            <span className="contact-text-row">Falco, obrt za usluge</span>
                            <span className="contact-text-row">vl.Ivan Sokol</span>
                            <span className="contact-text-row">Kralja Petra Krešimira IV 23</span>
                            <span className="contact-text-row">21300 Makarska</span>
                            <span className="contact-text-row">Hrvatska</span>
                            <span className="contact-text-row">OIB: 04965809364</span>
                            <span className="contact-text-row contact-phone">Phone: <a href="tel:+385 98 363 840"><b>+385 98 363 840</b></a></span>
                            <span className="contact-text-row">Email: <a href="mailto:info@makarska.rent"><b>info@makarska.rent</b></a></span>
                        </div>
                        <div className="contact-form">
                            <span className="contact-heading">Send us a message</span>
                            <ContactForm backToHome={this.redirectToHome}/>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer />
            </div>
        );
    }
}

export default Contact;