import React, { Component } from 'react';
import './App.css';
import { Route } from 'react-router';
import { Home } from './components/home/home';
import Navigation from './components/navigation/navigation';
import GetAQuote from './components/getAQuote/getAQuote';
import './animate.css';
import Contact from './components/contact/contact';
import Locations from './components/locations/locations';
import PrivacyPolicy from './components/privacyPolicy/privacyPolicy';
import TermsOfService from './components/termsOfService/termsOfService';
import {Helmet} from 'react-helmet';

class App extends Component {
  state = {navHidden : false};
  
  componentWillMount() {
    // When this component mounts, begin listening for scroll changes
    this.setState({prevScrollpos : window.pageYOffset});
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    // If this component is unmounted, stop listening
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    
    var prevScrollpos = this.state.prevScrollpos;
    var currentScrollPos = window.pageYOffset;

    if (!this.state.navHidden && prevScrollpos < currentScrollPos && currentScrollPos > 60 ) {
      this.setState({ navHidden:true});
    } 
    else if(this.state.navHidden && prevScrollpos > currentScrollPos) {
      this.setState({ navHidden:false});
    }
    this.setState({prevScrollpos : currentScrollPos});
  }
  homeSelected = () => {
    setTimeout( () => {
      window.scroll(0,-50);
    },5)
  }


  render() {
    return (
      <div className="App Ubuntu" >
        <Navigation hidden = {this.state.navHidden} homeSelected = {this.homeSelected}/>
        <Route exact path='/'  component={Home} />
        <Route path='/locations' component={Locations} />
        <Route path='/contact' component={Contact} />
        <Route path='/GetAQuote/:carId' render = {(props) => <GetAQuote {...props} />} />
        <Route path='/privacyPolicy' component={PrivacyPolicy} />
        <Route path='/termsOfService' component={TermsOfService} />
      </div>
    );
  }
}

export default App;
