import React, { Component } from 'react';

import 'simplebar'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'simplebar/dist/simplebar.css';

import {DatePicker} from '../datePicker/DatePicker';

import './dropdown.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class Dropdown extends Component {
    constructor(props)
    {
        super(props);
        this.state = {isVisible : false,selected : null};

    }
    componentWillReceiveProps(nextProps)
    {
        if(this.props !== nextProps)
        {
            this.setState({isVisible : nextProps.isVisible, selected : this.props.title});
        }
    }
    handleShowDropdown = () => {
        this.props.closeOthers(this.props.id);
    }
    handleSelect = (event) => {
        let selected = event.target.textContent;
        this.props.getSelectedValue(selected);
    }
    render() 
    {
        if(this.props.options && this.props.options.length !== 0){
            var options = <ul className="dropdown-options">
            {
                this.props.options.map((option,i) => 
                    <li onClick={this.handleSelect} className="dropdown-option Yantramanav" key={i}>{option}</li>
                )
            }
            </ul>
        }
        var active = !this.props.isDateDropdown ?
            options : 
            <DatePicker isPickupCalendar = {this.props.isPickupCalendar} pickupDate = {this.props.pickupDate} haveDate = {this.props.haveDate ? this.props.haveDate : null} handleDateSelection = {this.props.handleDateSelection}/> 
        
        var isActive = this.props.active ? "dropdown-active":"";
        var isError = this.props.isError ? "dropdown-error-only-border" : this.props.errorMessage.length > 0 && !this.state.isVisible ? "dropdown-error" : "";
        return (
            <div>
                    <div onClick = {this.handleShowDropdown} className={
                        this.state.isVisible ? 
                        "dropdown dropdown-inactive dropdown-visible "+ isActive:
                        "dropdown dropdown-inactive "+ isError +" "+ isActive  
                        }>
                        
                        {this.props.errorMessage.length > 0 ? this.props.errorMessage : this.props.title}
                        { 
                            this.props.hideIcon ? "": 
                            <FontAwesomeIcon icon={this.props.icon} />
                        }
                    </div>
                    <div className={
                        this.state.isVisible ? 
                        this.props.isDateDropdown? "dropdown-list dropdown-list-datepicker" : "dropdown-list"
                        :
                         "dropdown-hidden"
                        } 
                        data-simplebar>
                        {active}
                    </div>
                
           </div>
        );
    }
}

export default Dropdown;