import React, { Component } from 'react';
import './home.css';
import DesktopReservationBar from '../desktopReservationBar/desktopReservationBar';
import Fleet from '../fleet/fleet';
import ExtraServices from '../extraServices/extraServices';
import Footer from '../footer/footer';
import { Helmet } from 'react-helmet';

export class Home extends Component {
    constructor(props)
    {
        super(props);
        this.state = {filter : ""};
    }
    getAQuote = (carId,selection) =>{
        this.props.history.push({pathname : "/GetAQuote/"+ carId, selection : selection,filter : this.state.filter});
        window.scrollTo(0,0);
    }
    getFilter = (filter) =>
    {
        this.setState({filter : filter})
    }
    render() {
        return (
            <div>
                <Helmet>
                    <title>Falco - Car Rental</title>
                    <meta name="description"
                        content="Are you looking to rent a car in Makarska? Falco Car Rental has some great deals -  Professional service and new car fleet. Get a quote online and get your best price.  Free delivery alongside Makarska Riviera."></meta>
                </Helmet>
                <div className="home-header" id="home">
                    <div className="home-header-content">
                        <div className="home-header-text animated fadeIn">
                                <div className="home-header-primary Ubuntu">
                                    <span className="primary-light">Enjoy your holidays.</span>
                                    <br />
                                    <span className="primary-dark">We'll take care of the rest.</span>
                                </div>
                                <div className="home-header-secondary Ubuntu">
                                    <span className="secondary-dark ">- Car delivery at Makarska Riviera</span>
                                    <br />
                                    <span className="secondary-dark">- Completely new car fleet</span>
                                </div>
                        </div>


                        {/* reservation bar*/}
                        <DesktopReservationBar getAQuote={this.getAQuote}/>

                    </div>
                </div>
                <div id="fleet">
                    <Fleet getFilter = {this.getFilter} getAQuote = {this.getAQuote}/>
                </div>
                <ExtraServices />
                <Footer />
            </div>
        );
    }
}
