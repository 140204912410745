import React, { Component } from 'react';
import './getAQuote.css';
import {cars_getAQuote} from '../../data/car_fleet.js'; 
import Header from '../header/header';
import ExtraServices from '../extraServices/extraServices';
import Footer from '../footer/footer';
import EmailForm from '../emailForm/emailFrom';

class GetAQuote extends Component {
    constructor(props)
    {
        super(props);
        var carId = this.props.match.params["carId"];
        var cars = cars_getAQuote.filter(item => item.id == carId); 
        var selection = this.props.location.selection;
        var transmission = null;
        if(this.props.location.filter && this.props.location.filter.length) { 
            transmission = this.props.location.filter;
        }

        if(selection){
            this.state = { car : cars[0], selection : selection, transmission : transmission};
        }
        else{
            this.state={ car : cars[0], transmission : transmission };
        }
    }
    handleCarChange = (carName) => {
        var transmission = this.state.transmission;
        if(carName === "Volkswagen Passat")
        {
            transmission = "Automatic";
        }
        else if(this.props.location.filter && this.props.location.filter.length > 0)
        {
            transmission = this.props.location.filter;
        }
        else
        {
            transmission = null;
        }
        var cars = cars_getAQuote.filter(item => item.fullName == carName);
        this.setState({car : cars[0], transmission : transmission})
       
    }
    redirectToHome = () => {
        this.props.history.push("/");
        window.scrollTo(0,0);
    }
    
    render() {
        return (
            <div className="get-a-quote-wrap section_locations">
                <Header 
                primary = {"Get your reservation via email in few easy steps!"}
                secondary_first = {"We will answer in a short time with offer made just for you."}
                secondary_second = {"If reservation is sooner than 24 hours please call us directly : "}
                secondary_light={<a className="secondary-light" href="tel:+385 98 363 840">+385 98 363 840</a>} />
                <div className="gaq">
                    <div className="gaq-car-data animated fadeIn">
                            <img src={this.state.car.image} alt="car-image" />
                                <div className="gaq-car-name">{this.state.car.fullName}</div>
                                <div className="gaq-car-description">Category: {this.state.car.type}</div>
                                <div className="gaq-car-description">{this.state.car.person} Persons</div>
                                <div className="gaq-car-description">Air Condition</div>
                            <div className="gaq-car-extras">
                                Extras: Full protection insurance,<br /> GPS device, WIFI device and Child seat.
                            </div>
                    </div>
                    <div className="gaq-email-form">
                       <EmailForm transmission = {this.state.transmission} backToHome = {this.redirectToHome} car = {this.state.car} handleCarChange={this.handleCarChange} selection = {this.state.selection}/>
                    </div>
                </div>
                
                <ExtraServices backgroundColor = "#f1f2f2"/>
                <Footer />
            </div>
        );
    }
}

export default GetAQuote;