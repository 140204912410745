import React, { Component } from 'react';
import './fleet.css';
import {car_fleet} from '../../data/car_fleet.js';
import Car from '../car/car';

var FILTER_OPTIONS = ["All","4 Persons","5 Persons","7 Persons","Automatic","Manual"];

class Fleet extends Component {
    constructor(props)
    {
        super(props);
        this.state={ selectedFilter : 0}
    }
    handleFilterSelected = (event) => {
      var index = FILTER_OPTIONS.indexOf(event.target.textContent);
      this.setState({selectedFilter : index});
      if(index === 4 || index === 5)
      this.props.getFilter(event.target.textContent);
    }
    handleCarSelected = (carId) => {
        this.props.getAQuote(carId)
    }
    getCars= () => {

        var cars = car_fleet;
        if(this.state.selectedFilter === 0)
        {
            return cars;
        }
        else if(this.state.selectedFilter >= 1 && this.state.selectedFilter <= 3)
        {
            return cars.filter(item => item.person.includes(FILTER_OPTIONS[this.state.selectedFilter]));
        }
        else
        {
            return cars.filter(item => item.transmission.includes(FILTER_OPTIONS[this.state.selectedFilter]));
        }
        
    }
    render() {

        var cars = 
        <div className="cars-container row">
            {
                this.getCars().map((car,i) => 
                    <Car key={i}
                    id = {car.id} 
                    image = {car.image} 
                    name={car.name} 
                    transmission = {car.transmission} 
                    person = {car.person} 
                    type={car.type} 
                    ac={car.ac} 
                    handleCarSelected = {this.handleCarSelected}
                    />
                )}
        </div>
        return (
            <div className="fleet-wrap" id="section_fleet">
            <div className="fleet">
                   <div className="fleet-filter-wrap">
                    <ul className="filter-options" id="fleet-m">
                        <li className="filter-option">
                            <span onClick={this.handleFilterSelected} className={"option opt0-"+this.state.selectedFilter}>{FILTER_OPTIONS[0]}</span>
                        </li>  
                        <li className="filter-option">
                            <span className="distancer distancer-hidden">|</span>
                            <span onClick={this.handleFilterSelected} className={"option opt1-"+this.state.selectedFilter}>{FILTER_OPTIONS[1]}</span>
                            <span className="distancer">|</span>
                            <span onClick={this.handleFilterSelected} className={"option opt2-"+this.state.selectedFilter}>{FILTER_OPTIONS[2]}</span>
                            <span className="distancer">|</span>
                            <span onClick={this.handleFilterSelected} className={"option opt3-"+this.state.selectedFilter}>{FILTER_OPTIONS[3]}</span>
                        </li>
                        <li className="filter-option">
                            <span className="distancer distancer-hidden">|</span>
                            <span onClick={this.handleFilterSelected} className={"option opt4-"+this.state.selectedFilter}>{FILTER_OPTIONS[4]}</span>
                            <span className="distancer">|</span>
                            <span onClick={this.handleFilterSelected} className={"option opt5-"+this.state.selectedFilter}>{FILTER_OPTIONS[5]}</span>
                        </li>
                    </ul>
                   </div>

                   {cars}
                  
                   </div>
            </div>
        );
    }
}

export default Fleet;