import React, { Component } from 'react';

import './navigation.css';
import logoImg from '../../images/logo.png';
import menuIcon from '../../images/Icons/menu-icon.png';
import ScrollspyNav from 'react-scrollspy-nav';
import {HashLink as Link} from 'react-router-hash-link';
class Navigation extends Component {
    state = {show : false}
    handleClick = (event) => {
        this.setState({show : !this.state.show});
        if(event.target.textContent == 'HOME')
            this.props.homeSelected();
    }
    logoClick = () => {
        this.props.history.push("/");
    }
    render() {
        return (
        <div className={this.props.hidden ? "nav-wrap nav-hidden" : "nav-wrap"}>
           <div className="nav-bar navbar">
                <div className="nav-logo"  >
                <Link to="/#home" onClick = {this.props.homeSelected}><img className="logo-img" src={logoImg} alt="logo"/></Link>
                </div>
                <div className="nav-links-wrap d-none  d-lg-block">
                    <ul className="nav-links Ubuntu">
                        <li className="nav-link" ><Link to="/#home" onClick = {this.props.homeSelected}>HOME</Link></li>
                        <li className="nav-link"><Link to="/#fleet" >FLEET</Link></li>
                        <li className="nav-link"><Link to="/locations" >LOCATIONS</Link></li>
                        <li className="nav-link"><Link to="/contact" >CONTACT</Link></li>
                    </ul>
                </div>
                <div className="nav-menu-icon d-block d-lg-none">
                <button className="navbar-toggler" type="button" onClick={this.handleClick}>
                    <img src={menuIcon} alt="menu-icon" /> 
                </button>
                </div>
                

           </div>
           <div className={this.state.show ? "collapse menu-mobile show" : "menu-mobile collapse"} id="collapsibleNavbar">
           <ul className="mobile-nav-links">
                <li className="mobile-nav-link" >
                    <Link  to="/#home" onClick = {this.handleClick}>HOME</Link>
                </li>
                <li className="mobile-nav-link" >
                    <Link to="/#fleet-m" onClick = {this.handleClick}>FLEET</Link>
                </li>
                <li className="mobile-nav-link" >
                    <Link  to="/locations" onClick = {this.handleClick}>LOCATIONS</Link>
                </li>  
                <li className="mobile-nav-link ">
                    <Link to="/contact"  onClick = {this.handleClick}>CONTACT</Link>
                </li>   
           </ul>
       </div>  

        </div>
        );
    }
}

export default Navigation;