import React, { Component } from 'react';
import './contactForm.css';
import Button from '../button/button';
import * as EmailValidator from 'email-validator';
import ModalInfo from '../modal/modalInfo';

class ContactForm extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            name: "",
            email : "",
            subject : "",
            message: "",
            isEmailValid : true,
            showModal : false,
        }
        this.sendMail = this.sendMail.bind(this);
    }
   
    handleNameChange = (event) => {
           this.setState({name : event.target.value});
    }
    handleEmailChange = (event) => {
        this.setState({email : event.target.value,isEmailValid : true,});
    }
    handleSubjectChange = (event) => {
        this.setState({subject : event.target.value});
    }
    handleMessageChange = (event) => {
        this.setState({message : event.target.value});
    }
    
    handleFormSubmit = () =>{
        var email = true;

        if(!EmailValidator.validate(this.state.email))
           email = false;
        if(email)
        {
            this.sendMail();
            this.setState({showModal : true,isLoading : true});
        }
        else
        {
            this.setState({
                isEmailValid : email,
            });
        }
    }
    async sendMail(){
        var data = {
            name : this.state.name,
            email : this.state.email,
            subject : this.state.subject,
            message : this.state.message,
        };
        fetch("https://us-central1-falco-30c69.cloudfunctions.net/app/contactUs",
        {
            method : 'POST',
            body : JSON.stringify(data),
            
        })
        .then(res => {
            if(res.status === 200) { 
                this.setState({success : true, validationMessage : "Email was sent successfully. We will contact you in short time.", isLoading : false})
            }
            else
                this.setState({success : false, validationMessage : "There was a problem handling your request. Please try again.",isLoading : false})
        })
        .catch(() => this.setState({success : false,validationMessage:"There was a problem handling your request. Please check your Internet connection and try again.",isLoading : false}))
    }
    closeModal = () => {
        this.setState({showModal : false});
    }
    render() {
        var emailValid = "";
        if(!this.state.isEmailValid)
            emailValid = "text-input-error";

        var emailMessage = "Please fill required field."
        if(this.state.email.length > 0)
        emailMessage = "Please enter valid email address."
        return (
            <div className="email-form">
                <span className="email-form-row">
                    <input type="text" className={this.state.name.length > 0 ? "text-input text-input-active":"text-input"} value={this.state.name} onChange={this.handleNameChange} placeholder="Name" />
                    <span>
                        <input type="text" className={this.state.email.length > 0 ? "text-input text-input-active "+emailValid:"text-input "+emailValid} value={this.state.email} onChange={this.handleEmailChange} placeholder="*Email adress" />
                        <label className={this.state.isEmailValid ? "hidden":"error-label"}>{emailMessage}</label>
                    </span>
                    <input type="text" className={this.state.subject.length > 0 ? "text-input text-input-active":"text-input"} value={this.state.subject} onChange={this.handleSubjectChange} placeholder="Subject" />
                </span>
                <span>
                    <textarea type="text" className={this.state.message.length > 0 ? "text-input text-input-area text-input-active":"text-input text-input-area"} value={this.state.message} onChange={this.handleMessageChange} placeholder="Message" />
                </span>
                <div className="contact-form-bottom">
                    <Button handleClickEvent={this.handleFormSubmit} width="130px" text="SUBMIT" ></Button>
                </div>
                <ModalInfo backToHome = {this.props.backToHome} visible={this.state.showModal} isLoading = {this.state.isLoading} success = {this.state.success} message={this.state.validationMessage} closeModal = {this.closeModal} />
            </div>
        );
    }
}

export default ContactForm;