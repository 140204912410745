import React, { Component } from 'react';
import './extraServices.css';
import {DriverIcon, DropOffIcon,GpsIcon,LongTermIcon,OnlineBookingIcon,PickupIcon,RoadAssistIcon,SCDWIcon} from '../../icons/svgIcons';


class ExtraServices extends Component {
    render() {
        return (
            <div style={{backgroundColor : this.props.backgroundColor}} className="extra-services-wrap">
                <div className="extra-services">
                   <div className="extra-services-text">
                            Feel free to contact us about and other <span className="extra-services-text-light">extra services</span> or <br className="d-none d-lg-block"/><span className="extra-services-text-light">extra car equipment</span> suitable your needs. 
                   </div> 
                   <div className="extra-services-services">
                        <div className="extra-services-service">
                            <span className="extra-service-icon">
                                <GpsIcon height={35} />
                            </span>
                            <span className="extra-service-title">
                                <span>GPS </span><span>Device</span>
                            </span>
                        </div>
                        <div className="extra-services-service">
                            <span className="extra-service-icon">
                                <OnlineBookingIcon height={35} />
                            </span>
                            <span className="extra-service-title">
                                <span>Online booking </span><span>discount</span>
                            </span>
                        </div>
                        <div className="extra-services-service">
                            <span className="extra-service-icon">
                                <DriverIcon height={35} />
                            </span>
                            <span className="extra-service-title">
                                <span>Hire </span><span>a driver</span>
                            </span>
                        </div>
                        <div className="extra-services-service">
                            <span className="extra-service-icon">
                                <PickupIcon height={35} />
                            </span>
                            <span className="extra-service-title">
                                <span>Flexible </span><span>pickup</span>
                            </span>
                        </div>
                        <div className="extra-services-service">
                            <span className="extra-service-icon">
                                <LongTermIcon height={35} />
                            </span>
                            <span className="extra-service-title">
                                <span>Long term </span><span>discounts</span>
                            </span>
                        </div>
                        <div className="extra-services-service">
                            <span className="extra-service-icon">
                                <SCDWIcon height={35} />
                            </span>
                            <span className="extra-service-title">
                                <span>SCDW </span><span>Insurance</span>
                            </span>
                        </div>
                        <div className="extra-services-service">
                            <span className="extra-service-icon">
                                <RoadAssistIcon height={35} />
                            </span>
                            <span className="extra-service-title">
                                <span>24/7 Road </span><span>assistance</span>
                            </span>
                        </div>
                        <div className="extra-services-service">
                            <span className="extra-service-icon">
                                <DropOffIcon height={35} />
                            </span>
                            <span className="extra-service-title">
                                <span>Flexible </span><span>drop off</span>
                            </span>
                        </div>
                   </div>
                </div>
            </div>
        );
    }
}

export default ExtraServices;