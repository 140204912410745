import up_img from '../images/Fleet/up.jpg';
import passat_img from '../images/Fleet/passat.jpg';
import golf_img from '../images/Fleet/golf.jpg';
import polo_img from '../images/Fleet/polo.jpg';
import troc_img from '../images/Fleet/troc.jpg';
import touran_img from '../images/Fleet/touran.jpg';

import gaq_up_img from '../images/GetAQuote/up.jpg';
import gaq_passat_img from '../images/GetAQuote/passat.jpg';
import gaq_golf_img from '../images/GetAQuote/golf.jpg';
import gaq_polo_img from '../images/GetAQuote/polo.jpg';
import gaq_troc_img from '../images/GetAQuote/troc.jpg';
import gaq_touran_img from '../images/GetAQuote/touran.jpg';


class Car{
    constructor(id,image,name,type,transmission,person,ac,fullName)
    {
        this.id = id;
        this.image = image;
        this.name = name;
        this.type = type;
        this.transmission = transmission;
        this.person = person;
        this.ac = ac;
        this.fullName = fullName;
    }
}

export var car_fleet = [
    new Car(0,up_img,"VW Up","Mini","Manual and Automatic","4 Persons","AC","Volkswagen UP"),
    new Car(1,polo_img,"VW Polo","City","Manual and Automatic","5 Persons","AC","Volkswagen Polo"),
    new Car(2,golf_img,"VW Golf","Compact","Manual and Automatic","5 Persons","AC","Volkswagen Golf"),
    new Car(3,troc_img,"VW T-Roc","SUV","Manual and Automatic","5 Persons","AC","Volkswagen T-Roc",),
    new Car(4,touran_img,"VW Touran","Mini Van","Manual and Automatic","7 Persons","AC","Volkswagen Touran"),
    new Car(5,passat_img,"VW Passat","Premium","Automatic","5 Persons","AC","Volkswagen Passat"),
];

export var cars_getAQuote = [
    new Car(0,gaq_up_img,"VW Up","Mini","Manual and Automatic","4","AC","Volkswagen UP"),
    new Car(1,gaq_polo_img,"VW Polo","City","Manual and Automatic","5","AC","Volkswagen Polo"),
    new Car(2,gaq_golf_img,"VW Golf","Compact","Manual and Automatic","5","AC","Volkswagen Golf"),
    new Car(3,gaq_troc_img,"VW T-Roc","SUV","Manual and Automatic","5","AC","Volkswagen T-Roc",),
    new Car(4,gaq_touran_img,"VW Touran","Mini Van","Manual and Automatic","7","AC","Volkswagen Touran"),
    new Car(5,gaq_passat_img,"VW Passat","Premium","Automatic","5","AC","Volkswagen Passat"),
];