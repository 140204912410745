import React from "react";

//DRIVER
export const DriverIcon = ({
  style = {},
  fill = "#f39423",
  height = "100%",
  width = "50px",
  className = "",
  viewBox="0 0 45.35 34.02"
}) => (
  <svg
    width={width}
    style={style}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon ${className || ""}`}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
        fill={fill}
        className="cls-1" 
        d="M33.47,28.87c-.09-5.55-2.06-10.15-5.31-12.5a7.08,7.08,0,0,0,1.71-4.65,7.2,7.2,0,1,0-14.39,0,7.09,7.09,0,0,0,1.72,4.65c-3.26,2.35-5.23,7-5.32,12.5v.62H33.48Zm-20.35-.6c.23-5.19,2.2-9.37,5.34-11.26l.69-.42L18.57,16a5.92,5.92,0,0,1-1.86-4.32,6,6,0,1,1,11.94,0A5.92,5.92,0,0,1,26.79,16l-.58.55.68.42C30,18.9,32,23.08,32.23,28.27Z"
    />
  </svg>
);

//longterm

export const LongTermIcon = ({
    style = {},
    fill = "#f39423",
    height = "100%",
    width = "50px",
    className = "",
    viewBox="0 0 45.35 34.02"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
          fill={fill}
          className="cls-1" 
          d="M34.86,13,29.74,6.13h0A2.44,2.44,0,0,0,28,5.25H17.39a2.43,2.43,0,0,0-1.77.88L10.49,13a1.67,1.67,0,0,0,.06,2l11.16,13.3a1.39,1.39,0,0,0,.35.29l.08.19.18-.08a1.13,1.13,0,0,0,.36.06,1.43,1.43,0,0,0,.29,0l.13.05,0-.12a1.36,1.36,0,0,0,.5-.36L34.8,15A1.67,1.67,0,0,0,34.86,13ZM28.81,6.83l4.92,6.59H29.05l-5.22-7H28A1.28,1.28,0,0,1,28.81,6.83ZM17.63,13.42l5-6.7,5,6.7Zm10.26,1.15L22.62,26.94,17.35,14.57ZM16.54,6.83a1.28,1.28,0,0,1,.85-.43h4l-5.22,7H11.62Zm-4.83,7.74H16.1l4.52,10.62Zm17.44,0h4.5L24.5,25.47Z"
      />
    </svg>
  );

  //GPS
  
export const GpsIcon = ({
    style = {},
    fill = "#f39423",
    height = "100%",
    width = "50px",
    className = "",
    viewBox="0 0 45.35 34.02"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill={fill} className="cls-1" d="M32.38,11.6A9.81,9.81,0,0,0,13,11.6,9.5,9.5,0,0,0,12.86,13a9.69,9.69,0,0,0,1.08,4.47l7,12.17a2.05,2.05,0,0,0,1.72,1.14,2,2,0,0,0,1.71-1.14L30.83,18.5a8.19,8.19,0,0,0,.6-1A9.65,9.65,0,0,0,32.5,13,9.5,9.5,0,0,0,32.38,11.6Zm-2.19,5.23a9.94,9.94,0,0,1-.54.93L23.18,29c-.16.27-.35.44-.5.44s-.35-.17-.5-.44l-7-12.14a8.26,8.26,0,0,1-.92-3.8,9.79,9.79,0,0,1,.1-1.23A8.41,8.41,0,0,1,31,11.8,8.21,8.21,0,0,1,31.1,13,8.38,8.38,0,0,1,30.19,16.83Z"/>
      <path fill={fill} className="cls-1" d="M22.68,8.05a4.68,4.68,0,1,0,4.68,4.68A4.69,4.69,0,0,0,22.68,8.05Zm0,7.68a3,3,0,1,1,3-3A3,3,0,0,1,22.68,15.73Z"/>
    </svg>
  );


  //ONLINE BOOKING
  
export const OnlineBookingIcon = ({
    style = {},
    fill = "#f39423",
    height = "100%",
    width = "50px",
    className = "",
    viewBox="0 0 45.35 34.02"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill = {fill} className="cls-1" d="M34.63,5.69H10.73A3.65,3.65,0,0,0,7.08,9.35V24.67a3.65,3.65,0,0,0,3.65,3.65h23.9a3.66,3.66,0,0,0,3.65-3.65V9.35A3.66,3.66,0,0,0,34.63,5.69Zm-23.9,1.1h23.9a2.56,2.56,0,0,1,2.55,2.56v.78h-29V9.35A2.56,2.56,0,0,1,10.73,6.79Zm23.9,20.44H10.73a2.56,2.56,0,0,1-2.56-2.56V14.55h29V24.67A2.56,2.56,0,0,1,34.63,27.23Z"/>
      <path fill = {fill} className="cls-1" d="M17.24,23.9H9.93a.58.58,0,0,0,0,1.16h7.31a.58.58,0,0,0,0-1.16Z"/>
      <path fill = {fill} className="cls-1" d="M23.69,23.9H19a.58.58,0,1,0,0,1.16h4.71a.58.58,0,1,0,0-1.16Z"/>
      <path fill = {fill} className="cls-1" d="M28.84,23.9H25.29a.58.58,0,0,0,0,1.16h3.55a.58.58,0,0,0,0-1.16Z"/>
    </svg>
  );

  //Pickup
  
  export const PickupIcon = ({
    style = {},
    fill = "#f39423",
    height = "100%",
    width = "50px",
    className = "",
    viewBox="0 0 45.35 34.02"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
          fill={fill}
          className="cls-1" 
          d="M18.7,29a.92.92,0,0,1-.4-.08c-.78-.26-1.35-1.15-2.08-2.27-.24-.38-.49-.76-.76-1.15a3,3,0,0,1-1.75-.95A2.91,2.91,0,0,1,13,22.68c-.36-.32-.71-.61-1.05-.9-1-.86-1.83-1.54-2-2.34a1,1,0,0,1,.4-1.14c.86-.67,2.86-.7,5,.49l4.07-3.59a46.46,46.46,0,0,0-4.59-3.58C12.65,10.08,11.34,9.15,11.58,8a1.36,1.36,0,0,1,1-1c1.84-.73,7.21-.41,12.12,3.55l5.1-4.5a3.52,3.52,0,0,1,4.94,0,3,3,0,0,1,.68,2.49,4,4,0,0,1-1.34,2.4L29,15.41c3.31,5.36,3,10.73,2,12.46a1.36,1.36,0,0,1-1.14.85c-1.14.1-1.89-1.32-3.14-3.67a47.93,47.93,0,0,0-3-5l-4.08,3.59c.92,2.26.64,4.23-.13,5A1.1,1.1,0,0,1,18.7,29ZM11,19.17v0c.07.42.83,1.06,1.63,1.73.39.33.83.7,1.26,1.1l.18.17v.25a1.82,1.82,0,0,0,1.72,2l.25,0,.15.2c.35.48.66,1,.93,1.39.57.88,1.11,1.71,1.52,1.83h0c.29-.22.83-1.84-.24-4.14l-.17-.37,5.59-4.93.35.48a43.14,43.14,0,0,1,3.41,5.63c.74,1.38,1.65,3.09,2.08,3.09h.08a1.71,1.71,0,0,0,.42-.82c.64-2,.51-6.68-2.43-11.19l-.26-.4,5.81-5.12a2.92,2.92,0,0,0,1-1.75,1.91,1.91,0,0,0-.43-1.59,2.42,2.42,0,0,0-3.38.14L24.69,12l-.36-.31c-4.11-3.49-8.71-4.2-10.8-3.82-.62.11-.84.29-.87.34-.11.45,1.5,1.59,2.79,2.5a44.56,44.56,0,0,1,5.16,4.09l.43.41-5.59,4.93-.34-.22C13,18.59,11.28,18.92,11,19.17Z"
      />
    </svg>
  );

    //RoadAssist
  
export const RoadAssistIcon = ({
    style = {},
    fill = "#f39423",
    height = "100%",
    width = "50px",
    className = "",
    viewBox="0 0 45.35 34.02"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill={fill} className="cls-1" d="M13.55,19.33A3.65,3.65,0,1,0,17.2,23,3.66,3.66,0,0,0,13.55,19.33Zm0,6.32A2.67,2.67,0,1,1,16.22,23,2.68,2.68,0,0,1,13.55,25.65Z"/>
      <path fill={fill} className="cls-1" d="M34,19.33A3.65,3.65,0,1,0,37.69,23,3.66,3.66,0,0,0,34,19.33Zm0,6.32A2.67,2.67,0,1,1,36.7,23,2.68,2.68,0,0,1,34,25.65Z"/>
      <path fill={fill} className="cls-1" d="M34.68,15c-.51-.53-.92-1-1.29-1.45-1.54-1.82-2.48-2.94-7.16-3.21V9a1.65,1.65,0,1,0-3.29,0V10.3c-4.06.26-7.21,2.74-9.3,4.69C9.46,15.25,2.71,16.35,3,20.77a6.87,6.87,0,0,0,.6,2.81l.13.29H9.37l-.06-.54c0-.16,0-.31,0-.47a4.21,4.21,0,1,1,8.41,0,3.71,3.71,0,0,1,0,.47l0,.54H29.92l-.06-.54a3.71,3.71,0,0,1,0-.47,4.21,4.21,0,1,1,8.41,0c0,.16,0,.31,0,.47l-.06.54h3.52l.13-.29a7,7,0,0,0,.6-2.82C42.41,16.54,41.69,15.48,34.68,15ZM23.82,9a.77.77,0,1,1,1.53,0V10.2H23.82ZM41,22.89h-1.8v0a5.19,5.19,0,0,0-10.38,0v0H18.68v0a5.19,5.19,0,0,0-10.38,0v0h-4a6.23,6.23,0,0,1-.4-2.15C3.78,18,7.32,16.33,13.87,16h.18l.13-.12c2.08-2,5.33-4.58,9.48-4.58,6.51,0,7.33,1,9,2.94.41.48.87,1,1.47,1.63l.13.13h.19c6.78.45,7,1.15,7,4.8A6.2,6.2,0,0,1,41,22.89Z"/>
    </svg>
  );

    //SCDW
  
export const SCDWIcon = ({
    style = {},
    fill = "#f39423",
    height = "100%",
    width = "50px",
    className = "",
    viewBox="0 0 45.35 34.02"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path
          fill={fill}
          className="cls-1" 
          d="M32.91,9H30.34V8.86a4.2,4.2,0,0,0-4.19-4.2H19.21A4.21,4.21,0,0,0,15,8.86V9H12.45a4.17,4.17,0,0,0-4.17,4.17v12a4.17,4.17,0,0,0,4.17,4.16H32.91a4.17,4.17,0,0,0,4.16-4.16v-12A4.17,4.17,0,0,0,32.91,9ZM16.27,8.86a2.94,2.94,0,0,1,2.94-2.94h6.94a2.94,2.94,0,0,1,2.93,2.94V9H16.27Zm14.94,1.35V28.15H14V10.21Zm-21.72,15v-12a3,3,0,0,1,3-3h.29V28.15h-.29A3,3,0,0,1,9.49,25.19Zm26.38,0a3,3,0,0,1-3,3h-.44V10.21h.44a3,3,0,0,1,3,3Z"
      />
    </svg>
  );

    //DropOff
  
export const DropOffIcon = ({
    style = {},
    fill = "#f39423",
    height = "100%",
    width = "50px",
    className = "",
    viewBox="0 0 45.35 34.02"
  }) => (
    <svg
      width={width}
      style={style}
      height={height}
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon ${className || ""}`}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <path fill={fill} className="cls-1" d="M13.55,17.89a3.65,3.65,0,1,0,3.65,3.65A3.66,3.66,0,0,0,13.55,17.89Zm0,6.32a2.67,2.67,0,1,1,2.67-2.67A2.68,2.68,0,0,1,13.55,24.21Z"/>
      <path fill={fill} className="cls-1" d="M34,17.89a3.65,3.65,0,1,0,3.65,3.65A3.66,3.66,0,0,0,34,17.89Zm0,6.32a2.67,2.67,0,1,1,2.66-2.67A2.68,2.68,0,0,1,34,24.21Z"/>
      <path fill={fill} className="cls-1" d="M34.68,13.55c-.51-.52-.92-1-1.29-1.44C31.61,10,30.62,8.83,23.66,8.83c-4.41,0-7.81,2.66-10,4.72C9.46,13.8,2.71,14.9,3,19.33a6.87,6.87,0,0,0,.6,2.81l.13.29H9.37l-.06-.55c0-.15,0-.3,0-.46a4.21,4.21,0,1,1,8.41,0,3.44,3.44,0,0,1,0,.46l0,.55H29.92l-.06-.55a3.44,3.44,0,0,1,0-.46,4.21,4.21,0,1,1,8.41,0c0,.16,0,.31,0,.46l-.06.55h3.52l.13-.29a7.08,7.08,0,0,0,.6-2.82C42.41,15.1,41.69,14,34.68,13.55ZM41,21.45h-1.8v0a5.19,5.19,0,0,0-10.38,0v0H18.68v0a5.19,5.19,0,0,0-10.38,0v0h-4a6.28,6.28,0,0,1-.4-2.16c-.15-2.71,3.39-4.4,9.94-4.77h.18l.13-.12c2.08-2,5.33-4.58,9.48-4.58,6.51,0,7.33,1,9,2.93.41.49.87,1,1.47,1.64l.13.13h.19c6.78.45,7,1.15,7,4.79A6.26,6.26,0,0,1,41,21.45Z"/>
    </svg>
  );



