import React,{Component} from 'react';
import Modal from 'react-bootstrap4-modal';
import Button from '../button/button';
import './modalInfo.css';
import { Reject } from '../success_reject/rejectComponent';
import { Success } from '../success_reject/successComponent';

export default class ModalInfo extends Component{
    closeModal = () => {
        if(this.props.success)
        {
            this.props.backToHome();
        }
        this.props.closeModal();
        
    }
    render()
    {
        var status = this.props.success ?
        <Success/>
        :
        <Reject />
        var active = this.props.isLoading ? 
        <div className="loading-modal"><h5 className="loading-modal-heading">Please wait....</h5></div>
        :
        <div>
            <div className="modal-body">
                {status}
                <p className="modal-message">{this.props.message}</p>
                {this.props.showPhoneCallMessage ? <p className="modal-phone-call">If reservation is sooner than 24 hours please call us directly<br /> <a className="modal-phone" href="tel:+385 98 363 840">+385 98 363 840</a></p>:<></>}
            </div>
            <div className="modal-footer">
                <Button width="40%" text="OK" handleClickEvent = {this.closeModal}/>
            </div>
        </div>

        return(
            <Modal visible={this.props.visible} onClickBackdrop={this.modalBackdropClicked}>
                {active}
            </Modal>
        )
    }
}