import React, { Component } from 'react';
import './termsOfService.css';
import Header from '../header/header';
import Footer from '../footer/footer';
import { Helmet } from 'react-helmet';

class TermsOfService extends Component {
    componentDidMount(){
        window.scrollTo(0,0);
    }
    render() {
        return (
            <div className="ts-wrap">
                <Helmet>
                    <title>Falco - Terms of service</title>
                    <meta name="description" 
                    content="Falco Rent a Car, terms of service" 
                    />
                </Helmet>
                <Header primary ={"Terms of service"}/>
                <div className="ts">
                    <div className="ts-point">
                        <div className="ts-heading">
                            General terms
                        </div>
                        <div className="ts-text">
                        Renter acknowledges and agrees with the signature on the rental agreement:
                            <ul><br />
                                <li className="ts-list-el">that he will return vehicle together with all equipment and accessories to the place and
                                on time noted in this rental agreement or </li>
                                <li className="ts-list-el"> sooner upon demand of lessor</li>
                                <li className="ts-list-el">that he will ask lessor for prolongation of rental agreement 48 hours before it`s
                                expiration </li>
                                <li className="ts-list-el"> to take care of the technical condition of the car and to provide obligatory periodic
                                service of the car</li>
                                <li className="ts-list-el"> to check regularly and add water, oil or check tyre pressure if needed</li>
                                <li className="ts-list-el">  to properly maintain the rented car with all necessary care</li>
                                <li className="ts-list-el"> not to use the car for illegal purposes (such as in connection with criminal deeds,
                                costums or foreign currency offences), for</li>
                                <li className="ts-list-el"> instructing other drivers, for propel or tow a vehicle or trailer or for participating in
                                motorsport events</li>
                                <li className="ts-list-el"> to use the rented car only for his/her personal use and not to rent or to lend it to third
                                persons</li>
                                <li className="ts-list-el"> to drive the rented car himself or by the person authorized by the rental agreement</li>
                                <li className="ts-list-el"> not to overload the rented car with persons or goods over the permitted maximum</li>
                                <li className="ts-list-el"> renter is not allowed and cannot cross Croatian border without lessor`s permission
                                which must be entered on this agreement</li>
                                <li className="ts-list-el"> renter is not allowed to make any changes of parts or assembles on the rented car
                                without previous authorisation from lessor</li>
                                <li className="ts-list-el"> costs of fuel during the rent shall be covered by renter</li>
                                <li className="ts-list-el">the quantity of fuel when returning the car must equal the amount of fuel when picking
                                up the car </li>
                                <li className="ts-list-el"> renter is responsible for traffic/parking/police violations he caused while rental period</li>
                                <li className="ts-list-el"> renter is responsible for extremely dirty vehicle and should be charged for the cleaning
                                of the vehicle</li>
                            </ul>
                        </div>
                    </div>


                    <div className="ts-point">
                        <div className="ts-heading">
                            Payment
                        </div>
                        <div className="ts-text">
                            We accept credit cards, debit cards and cash for payments. When picking up the car
                            authorisation can be made on credit card or cash deposit taken.
                        </div>
                    </div>

                    <div className="ts-point">
                        <div className="ts-heading">
                            Insurance
                        </div>
                        <div className="ts-text">
                        All cars are provided with third party liability coverage. Excess amount depends on the group
                        of car rented.<br/><br />
                        <b>CDW</b> – Collision Damage Waiver – basic insurance that is included in price of the car. The
                        renter reduces his/her liability for damage to the amount of excess amount, which depends on
                        the car group, and if the damage is smaller than the excess amount, the renter pays that
                        smaller damage amount. To claim this insurance renter needs to follow the steps that are
                        written in „Procedure in case of accident“ in the text above.<br />
                        <b>TP</b> – Theft Protection – the renter limits his/her liability for this type of damage up to the
                        amount of excess in case that car gets stolen. When reporting this type of damage user needs

                        to have the car keys and car documents, otherwise user will be responsible for whole damage
                        that was caused by car theft.<br />
                        <b>SCDW</b> – Super Collision Damage Waiver – optional insurance, daily supplement for reducing
                        renters liability to 0€
                        <br /><br />
                        The insurance does in no way cover:

                        <ul><br />
                            <li className="ts-list-el"> damage caused by the careless or negligent drive</li>
                            <li className="ts-list-el"> any damage not reported to the nearest police station and rental station</li>
                            <li className="ts-list-el"> damage to engine due to lack of oil or filling the car with wrong fuel</li>
                            <li className="ts-list-el"> damage caused by a driver under influence of alcohol or drugs</li>
                            <li className="ts-list-el"> damage caused by unauthorised driver</li>
                            <li className="ts-list-el"> damage done outside of the Croatia, if the border crossing wasn`t allowed by the
                                lessor</li>
                            <li className="ts-list-el">if user dont follow all the steps in the part „Procedure in case of accident“ in the text
                                above</li>
                        </ul>
                        <br/>
                        <div className="ts-text">
                            In case if any above circumstances apply, renter is responsible for whole amount of the
                            damage.
                        </div>
                        </div>
                    </div>

                    <div className="ts-point">
                        <div className="ts-heading">
                            Procedure in case of accident
                        </div>
                        <div className="ts-text">
                            Renter agrees to protect the interests of lessor and the lessor`s insurance company in case of
                            accident by:
                            <ul><br />
                                <li className="ts-list-el">contacting rental and police station even in case of slight damage and submitting to
                                police station written report of the accident</li>
                                <li className="ts-list-el"> obtaining names and addresses of parties involved</li>
                                <li className="ts-list-el"> not abandoning rented vehicle without adequate safeguarding and security</li>
                                <li className="ts-list-el"> in case of greater damage or if the persons are injured is obligated to notify the police,
                                    to wait until the police arrives and makes official inquiries and immediately notify
                                    rental station</li>
                                <li className="ts-list-el"> all police statements from the accident and alcohol testing results must be handed over
                                    to rental station when returning the car.</li>
                                <li className="ts-list-el"> if renter fails to take steps mentioned above, he agrees to be liable to lessor for all
                                    losses and consequences lessor should suffer thereof</li>

                            </ul>
                            <div className="ts-text"><br />
                            When collecting a vehicle, individual rental contract agreement is signed between „Lessor“
                            Falco, obrt za usluge with its headquarters located at Kralja Petra Krešimira IV, br.23, 21300
                            Makarska, OIB: 04965809364 and a „Lessee“ natural or legal person which is hiring a
                            vehicle. Contract authorizes the use of the vehicle, defines the vehicle pick up and drop off,
                            coverage, equipment and services included in the price and the method of payment for the
                            rental. The contract also contains information about the status of mileage, fuel level, damage
                            and possible shortcomings of the rental vehicle and other rights and obligations of both parties
                            who with their signature fully accept contract at the time of issuing and General terms of the
                            rental which are written on the rental contract.
                            </div>
                        </div>
                    </div>


                    

                </div>
                <Footer />
            </div>
        );
    }
}

export default TermsOfService;