import React, { Component } from 'react';
import './button.css';

class Button extends Component {
    render() {
        return (
            <div onClick = {this.props.handleClickEvent} style={ {width:this.props.width, margin:this.props.margin }} className="button Ubuntu" >
                {this.props.text}
            </div>
        );
    }
}

export default Button;