import React, { Component } from 'react';
import './DatePicker.css';
import {Day} from './Day.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons";

//var days = ['Nedjelja','Ponedjeljak','Utorak','Srijeda','Četvrtak','Petak','Subota'];
var months = ['January','February','March','April','May','June','July','August','Spetember','October','November','December'];


export class DatePicker extends Component {
    constructor(props)
    {
        super(props);
        let date = new Date();
        date.setHours(0,0,0,0);
        this.state = { today : date, month : date.getMonth(),year:date.getFullYear(), selectedDate : this.props.haveDate};
        this.getDates = this.getDates.bind(this);
        this.mapDates = this.mapDates.bind(this);
        this.monthDown = this.monthDown.bind(this);
        this.monthUp = this.monthUp.bind(this);
        this.handleDateSelection = this.handleDateSelection.bind(this);
        this.dateSelected = this.dateSelected.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }
    componentWillReceiveProps(nextProps)
    {
        if(nextProps !== this.props){
            if(!nextProps.isPickupCalendar && nextProps.pickupDate && (!this.state.selectedDate || this.state.selectedDate - nextProps.pickupDate < 0) && (nextProps.pickupDate.getFullYear() !== this.state.year || nextProps.pickupDate.getMonth() !== this.state.month))
            {
            this.setState({year : nextProps.pickupDate.getFullYear(), month : nextProps.pickupDate.getMonth()});
            }

        }
    }
    closeModal()
    {
        this.props.dateSelectedCloseModal();
    }
    monthUp()
    {
        if(this.state.month === 11)
            this.setState({month : 0,year: this.state.year + 1});
        else
            this.setState({month : this.state.month + 1});
    }
    monthDown()
    {
        if(this.state.month > this.state.today.getMonth() || this.state.year > this.state.today.getFullYear())
        {
            if(this.state.month === 0)
                this.setState({month : 11,year: this.state.year - 1});
            else
                this.setState({month : this.state.month - 1});
        }
    }
    handleDateSelection(selectedDate)
    {
        this.setState({selectedDate : selectedDate});
        this.props.handleDateSelection(selectedDate);
    }
    dateSelected()
    {
        this.props.dateSelected(this.state.selectedDate);
        this.props.dateSelectedCloseModal();
    }
    getDates(year, month) 
    {
        //GET NUMBER OF DAYS IN MONTH
        //let FirstDay = new Date(year, month, 1); 
        let LastDay = new Date(year, month + 1, 0);  
        let dates = [];
        let blank_before = 0;
        let blank_after = 0;
        let blank_b = [];
        let blank_a = [];

        for(let i=0 ; i<LastDay.getDate() ; i++)
        {
            dates.push({ date : new Date(year,month,i+1)});
        }

        if(dates[0].date.getDay() === 0)
            blank_before = 6;
        else if(dates[0].date.getDay() === 1)
            blank_before = 0;
        else
            blank_before = dates[0].date.getDay() - 1;

        if(dates[LastDay.getDay()].date.getDay() > 0)
            blank_after = 7 - dates[LastDay.getDay()].date.getDay();

        for(let i=0 ; i<blank_before ; i++){
            blank_b[i] = {blank : true};
        }
        for(let i=0 ; i<blank_after ; i++){
            blank_a[i] = {blank : true};
        }
        
        return {dates:dates ,blank_before : blank_b,blank_after:blank_a};    
    }

    mapDates(list_of_dates)
    {   
        let isDisabled = "";
        let isBeforeToday = false;
        let isBeforePickup = false;
        let isPickupDate = false;
        let isBetween = false;
      
        let dates = list_of_dates.map((item,i)=>
        {

            item.date.setHours(0,0,0,0);
            if((item.date - this.state.today) < 0)
            isBeforeToday = true;
            else
            isBeforeToday = false;
            
            if(!this.props.isPickupCalendar)
            {
            if(this.props.pickupDate && (item.date - this.props.pickupDate) < 0)
                isBeforePickup = true;
            else
                isBeforePickup = false;
            }
            if(this.props.pickupDate && (item.date - this.props.pickupDate === 0))
            {
                isPickupDate = true;
            }
            else
            isPickupDate = false;
            
            if(isBeforeToday || isBeforePickup)
                isDisabled = "disabled-date";
            else
                isDisabled = "";
            
            
            if(this.props.pickupDate && this.state.selectedDate)
            {
                if(item.date - this.props.pickupDate > 0 && item.date - this.state.selectedDate < 0)
                    isBetween = true;
                else
                    isBetween= false;
            }

            if((item.date - this.state.today) === 0)
            return(
            <Day key={i} isDisabled={isDisabled} isPickupDate = {isPickupDate} isBetween = {isBetween} isToday = "today" selected={this.state.selectedDate} date={item.date} handleDateSelection = {this.handleDateSelection}/>
            )
            else
            return(
            <Day key={i} isDisabled={isDisabled} isPickupDate = {isPickupDate} isBetween = {isBetween} isToday="" date={item.date}  selected={this.state.selectedDate} handleDateSelection = {this.handleDateSelection}/>
            )
        })
        return dates;
    }
    render() {
        let list_of_dates = this.getDates(this.state.year,this.state.month);
        let blank_before = 
        list_of_dates.blank_before.map((blank,i) => 
            <div key = {i} className="blank day">
            </div>
        );
        let blank_after = 
        list_of_dates.blank_after.map((blank,i) => 
            <div key = {i} className="blank day">
            </div>
        );
        let dates = this.mapDates(list_of_dates.dates);
        
        return (
                <div className = "calendar-container">
                    <div className="calendar-header">
                        <span className="header-arrow" onClick={this.monthDown}><FontAwesomeIcon icon={faAngleLeft} /></span> 
                        <span className="header-current-month">{months[this.state.month]+" "+this.state.year}</span>
                        <span className="header-arrow" onClick={this.monthUp}><FontAwesomeIcon icon={faAngleRight} /></span>
                    </div>
                    <div className = "calendar-dates">
                        <div className="day d_h">MO</div>
                        <div className="day d_h">TU</div>
                        <div className="day d_h">WE</div>
                        <div className="day d_h">TH</div>
                        <div className="day d_h">FR</div>
                        <div className="day d_h">SA</div>
                        <div className="day d_h">SU</div>
                        {blank_before}
                        {dates}
                        {blank_after}
                    </div>
                   
                </div>
        );
    }
}