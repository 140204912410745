import React, { Component } from 'react';
import './desktopReservationBar.css';
import Dropdown from '../dropdown/dropdown';
import Button from '../button/button';
import  {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons';

var cars = ["Volkswagen UP","Volkswagen Polo","Volkswagen Golf","Volkswagen T-Roc","Volkswagen Touran","Volkswagen Passat"];
var locations = ["Makarska","Baška Voda","Baško Polje","Bratuš","Brela","Drvenik","Gradac","Igrane","Krvavica","Podaca","Podgora","Promajna","Tučepi","Živogošće","Airport Dubrovnik","Airport Split","Airport Zadar","Airport Zagreb","Other"];

class DesktopReservationBar extends Component {
    constructor(props)
    {
        super(props);
        this.state = {
            car : null, 
            pickupLocation: null,
            pickupDate: null,
            returnDate : null,
            carsVisible: false,
            locationsVisible : false,
            pickupVisible : false,
            returnVisible : false,
            showErrorMessage : false,
        }
    }
    handleCarSelected = (selected) => {
        this.setState({car : selected, 
            carsVisible: false,
            locationsVisible : false,
            pickupVisible : false,
            returnVisible : false,
            showErrorMessage : false,
        });
    }
    handlePickupLocationSelected = (selected) => {
        this.setState({pickupLocation : selected,
            carsVisible: false,
            locationsVisible : false,
            pickupVisible : false,
            returnVisible : false,});
    }
    handlePickupDateSelected = (selected) => {
        
        var returnDate = this.state.returnDate;
        if(returnDate && selected - returnDate > 0)
        {
            returnDate  = null;
        }
        
        this.setState({pickupDate : selected,
            returnDate : returnDate,
            carsVisible: false,
            locationsVisible : false,
            pickupVisible : false,
            returnVisible : false,});
    }
    handleReturnDateSelected = (selected) => {
        this.setState({returnDate : selected,
            carsVisible: false,
            locationsVisible : false,
            pickupVisible : false,
            returnVisible : false,});
    }
    closeOthers = (key) => {
       switch(key)
       {
           case 0 : 
            this.setState({
                carsVisible : !this.state.carsVisible,
                locationsVisible : false,
                pickupVisible : false,
                returnVisible : false}); 
            break;
           case 1:
            this.setState({
                carsVisible : false,
                locationsVisible : !this.state.locationsVisible,
                pickupVisible : false,
                returnVisible : false}); 
           break;
           case 2: 
           this.setState({
                carsVisible : false,
                locationsVisible : false,
                pickupVisible : !this.state.pickupVisible,
                returnVisible : false}); 
           break;
           case 3: 
           this.setState({
                carsVisible : false,
                locationsVisible : false,
                pickupVisible : false,
                returnVisible : !this.state.returnVisible,}); 
           break;
           default : break;
       }
    }

    handleNextButtonClick = () => {
        if(!this.state.car)
            return this.setState({showErrorMessage : true});
        let carId = cars.indexOf(this.state.car);
        this.props.getAQuote(carId,{pickupLocation : this.state.pickupLocation, pickupDate : this.state.pickupDate,returnDate : this.state.returnDate});
        this.setState({showErrorMessage : false});
    }


    render() {
        return (
            <div className="desktop-reservation-bar">
                <Dropdown id={0} errorMessage = {!this.state.showErrorMessage ? "":"Please fill required field."} closeOthers = {this.closeOthers} isVisible = {this.state.carsVisible} title={this.state.car? this.state.car:"Pick a car"} active = {this.state.car ? true:false} getSelectedValue = {this.handleCarSelected} options = {cars} icon={faAngleDown}/>
                <Dropdown id={1} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.locationsVisible} title={this.state.pickupLocation? this.state.pickupLocation:"Pickup location"} active = {this.state.pickupLocation ? true:false} getSelectedValue = {this.handlePickupLocationSelected} options = {locations} icon={faAngleDown}/>
                <Dropdown id={2} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.pickupVisible} title={this.state.pickupDate? this.state.pickupDate.toLocaleDateString():"Pickup date"} active = {this.state.pickupDate ? true:false} icon={faCalendarAlt} isDateDropdown = {true} handleDateSelection={this.handlePickupDateSelected} isPickupCalendar = {true}/>
                <Dropdown id={3} errorMessage = "" closeOthers = {this.closeOthers} isVisible = {this.state.returnVisible} title={this.state.returnDate? this.state.returnDate.toLocaleDateString():"Return date"} active = {this.state.returnDate ? true:false} pickupDate = {this.state.pickupDate} icon={faCalendarAlt} isDateDropdown = {true} handleDateSelection={this.handleReturnDateSelected} isPickupCalendar = {false}/>
                <div className="button-wrap">
                    <Button text="NEXT" handleClickEvent={this.handleNextButtonClick}/>
                </div>
            </div>
        );
    }
}

export default DesktopReservationBar;