import React, { Component } from 'react';
import './locations.css';
import Header from '../header/header';
import {list_of_locations} from '../../data/locations';
import Location from './location';
import Footer from '../footer/footer';
import { Helmet } from 'react-helmet';

class Locations extends Component {
    componentDidMount(){
        window.scrollTo(0,0);
    }
    render() {

        var locations = <div className="row">{
            list_of_locations.map((location,i) =>
            {
                if(i===0)
                    return <Location key = {i} big = {true} heading = {location.name} bodyText = {location.text} free = {location.isFree} />
                else
                    return <Location key = {i} big = {false} heading = {location.name} bodyText = {location.text} free = {location.isFree} />

            }
            )
        }
        </div>
        

        return (
            <div className="locations-wrap">
                <Helmet>
                    <title>Falco - Locations</title>
                    <meta name="description" 
                     content="Professional and affordable Car Rental company. If you need to rent a car in Makarska, Brela, Tucepi, Podgora, Promajna or Baska Voda we offer Free delivery to Your Hotel or adress. Get a quote online for best price." 
                    />
                </Helmet>
                <Header primary = {"Find out a little bit more about locations on Makarska riviera!"}
                    secondary_first = {"Let us tell you something about them and what we can offer for you."}
                />
                <div className="list-of-locations container-fluid">
                    {locations}
                </div>
                <Footer />
            </div>
        );
    }
}

export default Locations;