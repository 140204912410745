import React, { Component } from 'react';
import './car.css';
import Button from '../button/button';

class Car extends Component {
    handleButtonClick= () => {
        this.props.handleCarSelected(this.props.id);     
    }
    render() {
       
        return (
            
                <div onClick = {this.handleButtonClick} className="car-wrap animated fadeIn">
                    <img src={this.props.image} alt=""/>
                    <span className="car-name">{this.props.name}</span>
                    <span className="car-type">{this.props.type}</span>
                    <span className="car-transmission">{this.props.transmission}</span>
                    <span className="car-person">{this.props.person}</span>
                    <span className="car-ac">{this.props.ac}</span>
                    <Button handleClickEvent = {this.handleButtonClick} width="150px" margin="auto" text = "GET A QUOTE" ></Button>
                </div>
                
            
        );
    }
}

export default Car;