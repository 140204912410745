import React, { Component } from 'react';
import './header.css';
class Header extends Component {
    render() {
        return (
            <div className="header-wrap animated fadeIn">
                <div className="header">
                    <div className="header-primary">
                        <span className="primary-light">{this.props.primary}</span>
                    </div>
                    <div className={this.props.secondary_first || this.props.secondary_second ? "header-secondary":"hidden"}>
                        <span className={this.props.secondary_first ? "secondary-dark":"hidden"}>- {this.props.secondary_first}</span>
                        <br />
                        <span className={this.props.secondary_second ? "secondary-dark":"hidden"}>- {this.props.secondary_second} <br className="d-lg-none"/><span className="secondary-light">{this.props.secondary_light}</span></span>
                    </div>
                
                </div>
            </div>
        );
    }
}

export default Header;