class Location{
    constructor(name,isFree,text){
        this.name = name;
        this.text = text;
        this.isFree = isFree;
    }
} 
export var list_of_locations = [

    new Location("Makarska",true,
    `Makarska, heart of Makarska Riviera. It's a city with more than 15 thousand inhabitants and one of the most famous tourist destination in Croatia. Makarska is located below the mountain of Biokovo which protects it from harsh continental climate. It is well known for its rich Mediterranean vegetation, mild winters and warm summers with average temperatures above 20°C from July to September.
    Makarska is also situated between two peninsulas, St. Peter and Osejava which are the most attractive places to visit and to enjoy the view.
    Sandy beaches are lined with pine trees and  surrounded by luxury hotels and tourist facilities. If you are into knowing more about the history of Makarska and its culture, then you should definitely visit the Franciscan monastery which recently celebrated its 500th anniversary.The city museum can be found on the waterfront, as well as the church of St. Filip. The renovated church of St. Petar is located in the St. Petar park.
    Once you're done with sightseeing in Makarska, there are still a lot of options for you to do and see. That's where we step in making sure you get the best of your holiday. Makarska is located near towns such as Split, Dubrovnik and Mostar or you can go and visit National park Krka or Kravice waterfalls. So many options for one day trips and Falco Rent a Car is there for you.
    We offer free car delivery to any address in Makarska.`),

    new Location("Promajna",true,
    `Promajna is small tourist place located between Baška Voda and Makarska, along the old coastal road. In recent times, due to the development of tourism, the village started to expand toward the coast. Promajna lies along a beautiful beach and place provides very good opportunities for peaceful vacations and pleasant walks. You can walk by the promenade to nearby Baska Voda. Entertainment programs are organized during the tourist season like fisherman nights. If you want to hire a car in Promajna, Falco Car Rental will deliver a car for free to any address in Promajna.`
    ),
    new Location("Krvavica",true,
    `Krvavica is a small settlement, situated between Bratuš and Makarska. The beauty of the coast here is very much like other places at Makarska Riviera. Amongst the sights in Krvavica you can find Babin zub which is a cliff shaping something like a tooth. Krvavica has one of the best harbours at Makarska Riviera so nautical tourism is highly developed. It is also possible to reach Krvavica on foot from Makarska and Baska voda. The promenade that goes along the coast from Krvavica continues to the famous tourist destination of Brela. If you want to rent a car in Krvavica, Falco Car Rental will be happy to deliver it for free.`  
    ),
    new Location("Bratuš",true,
    `
    Bratus is situated just nearby Krvavica. Its landscape is the combination of  white gravel beaches and blue sea. Makarska and Baska Voda are in walking distance by the beach promenade with beautiful and intact nature. The peace here is disturbed only by the sounds of the waves and sea gullls. Today Bratus is a small fisherman place oriented towards tourism whose center is full of small stone houses. In the center of Bratus you can find restaurant with Dalmatian cuisine, coffee bars, grocery store, tourist office and a small port for boats. If you need a car rental in Bratus, Falco rent a car delivers a car at any address in Bratus for free.
    `  
    ),
    new Location("Brela",true,
    `Brela is a small tourist town near Makarska which is most known by its beautiful long beaches and crystal blue sea. What makes it even more special is that you can enjoy the view on mountain Biokovo as well as on the  island of Brač. Brela has a long tradition in tourism and it's not for no reason, both natural and cultural heritage, Mediterranean climate, luxury hotels and private accomodation guarantee unforgettable holidays.You can also enjoy autochthonous Dalmatian meals, great wines and music while watching probably the most beautiful sunsets. Falco car rental is official partner of Bluesun Hotel Berulia, Bluesun Hotel Soline, Bluesun Hotel Marina and Bluesun hotel Maestral and if you need to rent a car in Brela we deliver cars free of charge. If you choose private accomodation, just contact us and we can deliver cars to any address in Brela also completely free of charge.`  
    ),
    new Location("Tučepi",true,
    `Tučepi is located only 2km from Makarska and it's ideal destination for families with children who can play on one of the most beautiful beaches.
    Tamarisk and pine trees create thick shade alongside the beach where you can lay, relax and enjoy.
    Tučepi offers its guests rich culinary delights in its hotels, restaurants and wine cellars. Those who are interested in active holidays have access to all the equipment needed for a variety of water sports, as well as access to other sporting facilities.
    The proximity of Biokovo is a true luxury for rock-climbers and for all who love walking outside in the open countryside.
    Tučepi has become a leading destination for travellers looking for tranquillity and relaxing light-heartedness in undisturbed natural surroundings and it continues to win prestigious awards for the high standard of its hospitality and tourist services. If you need to rent a car in Tucepi we are partnered with Blue Sun Hotels in Tučepi and we deliver cars to any hotel and address in Tučepi free of charge.
    `  
    ),
    new Location("Baška Voda",true,
    `The former fisherman's village turned into a modern tourist resort with all the contents of a small Mediterranean city. You can choose between numerous accomodation types which have a high level of service. If you are more of a camp kind of person, don't worry, you can find it in Baška Voda.
    You can experience as well active holidays as relaxing and peaceful one. Try diving, paragliding, hiking, para sailing and many other extreme sports or just take a walk on beautiful long pebble beaches. There are many cultural monuments to visit such as St. Nikola's Church, the small church of Sv. Roko, statue of St, Nikola and many others,
    If you need car rental in Baška Voda Falco Rent a Car will deliver car to any Hotel or any address  in Baška Voda free of charge.
    `  
    ),
    new Location("Podgora",true,
    `Its rarely beautiful landscape, the azure sky and the limpid sea make Podgora the peerless beauty spot of the Adriatic. Life in Podgora has always been and remains dedicated to the sea. The core of Podgora, as we know it today, evolved from the city's port, which still abounds in fishing boats. Seagull’s wings monument, which rises above the place, bears witness to its rich maritime history.
    This small, vivid town can offer you far more than you could have ever expected. Enjoy the traditional ambience with all the benefits of a modern approach to tourism . If you are looking for a car to rent in Podgora, we deliver cars for free to any hotel or address. Also we are official partners of Medora Auri Hotel in Podgora.  
    `  
    ),
    new Location("Igrane",true,
    `Igrane is a tourist destination on the Makarska Riviera, situated on a peninsula whose south-eastern coast
    occupies a large sandy beach. Igrane offers the atmosphere of a typical Dalmatian tourist resort for
    peaceful vacation. Igrane is located 15 kilometers south of Makarska. The tourist offer in Igrane is very
    rich and can satisfy everybody's taste and all types of guests. For the sport lovers, various sport activities
    are offered; there are numerous monuments and archaeological localities for lovers of historical
    monuments; beautiful beaches are adapted for the children so Igrane is perfect for a family vacation. If
    you need car rental in Igrane Falco Car Rental will deliver car to Igrane free of charge.`  
    ),
    new Location("Živogošće, Drvenik, Gradac and Podaca",false,
    `Those small places are located around 20km South form Makarska. They are beautiful small villages for
    perfect relaxing vacations. With a lot of Hotels and private accomodations tourism is largely developed.
    Falco rent a car offers free delivery to Zivogosce, Drvenik, Gradac and Podaca free of charge if the rental
    period is 3 days or longer, but if it is less then 3 days small fee may apply.
    `  
    ),
    new Location("Airport service",false,
    `Falco Car Rental also offers Airport service. If you are planning to pick up or drop off car at any airport, please send us request and we will try to give you a best offer.`  
    ),



];



